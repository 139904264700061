import { Button, Checkbox, Container, Content, Input, Modal, Placeholder } from "rsuite";
import $ from "jquery";
import { HiOutlineBellAlert } from "react-icons/hi2";
import { useState } from "react";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
export const HomeContent = () => {
  const [checked, setChecked] = useState(false);
  const [shake, setShake] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { t } = useTranslation('EN');

  const games = [
    {
      imgSrc: "banana clicker.webp",
      altText: "Banana Clicker",
      title: "Banana Clicker",
      link: "https://play.google.com/store/apps/details?id=com.ZwolloGames.BananaClicker",
    },
    {
      imgSrc: "skate gorilla.webp",
      altText: "Skate Gorilla",
      title: "Skate Gorilla",
      link: "https://play.google.com/store/apps/details?id=com.autoxize.skategorilla",
    },
    {
      imgSrc: "tower birds.webp",
      altText: "Tower Birds",
      title: "Tower Birds",
      link: "https://play.google.com/store/apps/details?id=com.zwollogames.fallingbirds",
    },
    {
      imgSrc: "feathered adventures.webp",
      altText: "Feathered adventures",
      title: "Feathered adventures",
      link: "https://play.google.com/store/apps/details?id=com.Autoxize.JumpyRoads",
    },
    {
    imgSrc: "toilet factory.webp",
      altText: "Toilet Factory",
      title: "Toilet Factory",
      link: "https://play.google.com/store/apps/details?id=com.autoxize.toiletfactory",
    },
  ];

  

  const subscribeMail = () => {
    if (!checked) {
      setShake(true);
      setTimeout(() => setShake(false), 500); // stop shaking after 500ms
    } else {
        const data = new FormData();
        data.append('email', document.getElementById('email').value);
        
        fetch('https://api.zwlsoftware.nl/autoxize/', {
          method: 'POST',
          body: data
        })
        .then(response => response.json())
        .then(data => {
            if(data?.error === 0)
            {
                //success
                setOpen(true); 
            }
            

        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
  };
  return (
    <Content >
      <Container className="min-h-[120vh] w-[100%] flex justify-between items-center bg-main">
        <Modal open={open} onClose={handleClose}>
            <Modal.Header>
            <Modal.Title>Thank you for signing up!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>You'll now receive news & updates from our games! <p className="text-gray-400"> <br/><br/> You can unsubscribe anytime within one of our emails.</p> </p>
            </Modal.Body>
            <Modal.Footer>
            <Button onClick={handleClose} className="w-[50%]" appearance="primary">
                Ok
            </Button>
            </Modal.Footer>
        </Modal>

        <div className="flex flex-wrap gap-5 md:ml-20 w-[80%]"> 

        <div className="w-full md:h-[80px] h-[40px]"></div>

          <div className="md:w-[70%] w-full md:p-5">
            <h1 className="text-white w-full text-7xl title  font-extralight text-start  my-2 ">
              About us
            </h1>
            <p className="mt-2 md:w-[80%] w-full  ">{ t('home.about') }</p>
          </div>

        <div className="w-full md:h-[180px] h-[130px]"></div>

          <div className=" md:flex justify-end items-center md:w-[100%] w-full md:p-5">
            <h1 className="text-white w-full text-7xl title  font-extralight text-start  my-2 ">
              In development
            </h1>
            <p className="mt-2 md:w-[50%]  ">Latest in development games by Autoxize Games </p>
            <Link to={'/devlogs'}> <Button className="button-main my-4 w-[209px]">View blogs</Button></Link>

          </div>
          {/* spacing */}
          <div className="w-full md:h-[200px] h-[130px]"></div>
          <div className="md:w-[100%] w-full md:p-5">
            <h5 className="text-white text-7xl title font-extralight md:text-center text-start  my-2">
              Mobile
            </h5>
            <p className="mt-2  md:text-center text-start ">Looking for something casual? Check out our mobile games for android. Available on the google playstore!</p>
            <div className="mt-10 flex flex-wrap sm:flex-nowrap  justify-center items-center gap-5 my-20">
            {games.map((game, index) => (
            <div key={index} className="image-container">
              <img src={game.imgSrc} alt={game.altText} />
              <div className="overlay">
                <p className="text-white">{game.title}</p>
                <a target="__blank" href={game.link}>
                  <Button className="button-main">Play now</Button>
                </a>
              </div>
            </div>
          ))}
            </div>

            {/* <h5 className="text-white text-5xl kanit font-extralight text-center md:text-start mt-20">WHO WE ARE</h5> */}
          </div>
                    {/* spacing */}
                    <div className="w-full md:h-[200px] h-[150px]"></div>
        </div>

        <div className="w-full p-5 bg-slate-900 mailing flex">
          <div className="chimp md:w-1/2 hidden md:flex justify-center items-center">
            <HiOutlineBellAlert></HiOutlineBellAlert>
          </div>

          <div className="md:w-1/2 md:p-5 min-h-[350px]">
            <div className="md:w-[500px]">
              <h5 className="text-white text-5xl scratched font-extralight my-2 ">
                STAY TUNED!
              </h5>
              <p className="text-white SometypeMono mt-2">
                {t('home.mailing')}
              </p>
              <br></br>
              <Input id="email"  placeholder="Email Adress" className="light-input"></Input>
              <Checkbox
                className={shake ? "shake" : ""}
                checked={checked}
                onChange={(value, event) => {
                  setChecked(!checked);
                  console.log(
                    `Checkbox is ${!checked ? "checked" : "not checked"}`
                  );
                }}
                id="mailsub_check"
              >
                <span className="SometypeMono text-gray-600">
                  I consent to having Autoxize Games store my submitted
                  information so they can respond to my inquiry. *
                </span>
              </Checkbox>
              <Button
                onClick={subscribeMail}
                className="md:w-[150px] SometypeMono w-[99%] md:float-right mt-4 button-main"
                appearance="default"
              >
                SUBSCRIBE!
              </Button>
            </div>
          </div>
        </div>
      </Container>
    </Content>
  );
};
